import { TripEditorLegStop } from '../entities';
import { isHHmmLessThanMin } from '@rootTypes/utils/common/time';

export const isTripEditorStopValid = (stop: TripEditorLegStop): boolean => {
  if (stop.isLast) {
    return !!stop.data.address;
  }
  const { address, arrivalDate, arrivalTime, departureTime } = stop.data;
  return (
    !!address && !!arrivalDate && !!arrivalTime && !!departureTime && !isHHmmLessThanMin(departureTime, arrivalTime)
  );
};
