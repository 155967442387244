import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'c-redirect',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
