import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';
import { HomeRouterService, TripListRouterService } from '../../../routes';

@Component({
  selector: 'c-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeHeaderComponent implements OnInit {
  public logo = iconPaths.ZUM_LOGO_HORIZONTAL;

  constructor(private homeRouter: HomeRouterService, private tripsRouter: TripListRouterService) {}

  public ngOnInit(): void {}

  public goHome(): void {
    this.homeRouter.navigate();
  }

  public goTrips(): void {
    this.tripsRouter.navigate();
  }
}
