import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hasPermission } from '../../store/auth/account.selectors';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';
import { map, take } from 'rxjs/operators';
import { CreateTripStopsRouterService, TripListRouterService } from '../../routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthRedirectGuard implements CanActivate {
  constructor(
    private store: Store,
    private bookingRoute: CreateTripStopsRouterService,
    private tripListRoute: TripListRouterService,
  ) {}
  canActivate(): Observable<UrlTree> {
    const hasBookingFlowPermission$ = this.store.select(hasPermission(CharterPermissions.CHARTER_TRIP_BOOKING_EDIT));
    return hasBookingFlowPermission$.pipe(
      take(1),
      map((hasBookingFlowPermission) => {
        if (hasBookingFlowPermission) {
          return this.bookingRoute.getUrlTree({});
        } else {
          return this.tripListRoute.getUrlTree({});
        }
      }),
    );
  }
}
