import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GoogleInitGuardService } from './core/guards';
import { createTripPath, homeRoute, tripsPath, unauthorizedRoute } from './routes';
import { HomeComponent } from './core/containers/home/home.component';
import { UnauthorizedComponent } from './core/containers/unauthorized/unauthorized.component';
import { tripDetailsPath } from './routes/trip-details/trip-details-path';
import { AuthInitGuard } from './core/guards/auth-init.guard';
import { BookingFlowAuthGuard } from './core/guards/booking-flow-auth.guard';
import { TripsAuthGuard } from './core/guards/trips-auth.guard';
import { AuthRedirectGuard } from './core/guards/auth-redirect.guard';
import { RedirectComponent } from './core/components/redirect/redirect.component';
import { AppConfigInitGuard } from './core/guards/app-config-init.guard';
import { noPermissionsRoute } from './routes/core/no-permissions';
import { NoPermissionsComponent } from './core/containers/no-permissions/no-permissions.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: homeRoute.getHref(),
    pathMatch: 'full',
  },
  {
    path: homeRoute.path,
    component: HomeComponent,
    canActivate: [AuthInitGuard, AppConfigInitGuard, GoogleInitGuardService],
    children: [
      {
        path: createTripPath,
        canActivate: [BookingFlowAuthGuard],
        loadChildren: () => import('./features/create-trip/create-trip.module').then((m) => m.CreateTripModule),
      },
      {
        path: tripsPath,
        canActivate: [TripsAuthGuard],
        loadChildren: () => import('./features/trips/trips.module').then((m) => m.TripsModule),
      },
      {
        path: `${tripDetailsPath}`,
        canActivate: [TripsAuthGuard],
        loadChildren: () => import('./features/trip-details/trip-details.module').then((m) => m.TripDetailsModule),
      },
      {
        path: '',
        canActivate: [AuthRedirectGuard],
        pathMatch: 'full',
        component: RedirectComponent,
      },
    ],
  },
  {
    path: unauthorizedRoute.path,
    component: UnauthorizedComponent,
  },
  {
    path: noPermissionsRoute.path,
    component: NoPermissionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
