import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

import { BaseApiService } from './base-api.service';
import * as fromApi from '@rootTypes/api';
import { GetCharterCatalogRequest, GetCharterCatalogResponse } from '@rootTypes/api';
import { CharterCatalogApiService } from './charter-catalog-api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
  private portalEndpoint = cEnvironment.userRole;

  constructor(private baseApi: BaseApiService, private catalogApi: CharterCatalogApiService) {}

  public cancelCharterTrip(request: fromApi.CancelCharterTripRequest): Observable<fromApi.CancelCharterTripResponse> {
    return this.baseApi.post('cancelCharterTrip', { ...request });
  }

  public createCharterCustomer(
    request: fromApi.CreateCharterCustomerRequest,
  ): Observable<fromApi.CreateCharterCustomerResponse> {
    return this.baseApi.post('createCharterCustomer', { ...request });
  }

  public createCharterTrip(request: fromApi.CreateCharterTripRequest): Observable<fromApi.CreateCharterTripResponse> {
    return this.baseApi.post('createCharterTrip', { ...request });
  }

  public entityFilter(request: fromApi.EntityFilterRequest): Observable<fromApi.EntityFilterResponse> {
    return this.baseApi.post('entityFilter', { ...request }, this.portalEndpoint);
  }

  public entitySearch(request: fromApi.EntitySearchRequest): Observable<fromApi.EntitySearchResponse> {
    return this.baseApi.post('entitySearch', { ...request }, this.portalEndpoint);
  }

  public findCharterCatalog(
    request: fromApi.FindCharterCatalogRequest,
  ): Observable<fromApi.FindCharterCatalogResponse> {
    return this.baseApi.post('findCharterCatalog', { ...request });
  }

  public getAccount(): Observable<any> {
    return this.baseApi.post('getAccount', undefined, this.portalEndpoint);
  }

  public getBillingOrganizations(
    request: fromApi.GetBillingOrganizationsRequest,
  ): Observable<fromApi.GetBillingOrganizationsResponse> {
    return this.baseApi.post('getBillingOrganizations', { ...request });
  }

  public getCampus(request: fromApi.GetCampusRequest): Observable<fromApi.GetCampusResponse> {
    return this.baseApi.post('getCampus', request, this.portalEndpoint);
  }

  public getCharterCatalog(request: GetCharterCatalogRequest): Observable<GetCharterCatalogResponse> {
    return from(this.catalogApi.getCharterCatalog(request));
  }

  public getCharterConfig(request: fromApi.GetCharterConfigRequest): Observable<fromApi.GetCharterConfigResponse> {
    return this.baseApi.post('getCharterConfig', { ...request });
  }

  public getCharterContract(
    request: fromApi.GetCharterContractRequest,
  ): Observable<fromApi.GetCharterContractResponse> {
    return this.baseApi.post('getCharterContract', { ...request });
  }

  public getCharterDirections(
    request: fromApi.GetCharterDirectionsRequest,
  ): Observable<fromApi.GetCharterDirectionsResponse> {
    return this.baseApi.post('getCharterDirections', { ...request });
  }

  public getCharterRide(request: fromApi.GetCharterRideRequest): Observable<fromApi.GetCharterRideResponse> {
    return this.baseApi.post('getCharterRide', { ...request });
  }

  public getCharterTrip(request: fromApi.GetCharterTripRequest): Observable<fromApi.GetCharterTripResponse> {
    return this.baseApi.post('getCharterTrip', { ...request });
  }

  public getCharterTripCancellationEstimate(
    request: fromApi.GetCharterTripCancellationEstimateRequest,
  ): Observable<fromApi.GetCharterTripCancellationEstimateResponse> {
    return this.baseApi.post('getCharterTripCancellationEstimate', { ...request });
  }

  public getCharterTripItinerary(
    request: fromApi.GetCharterTripItineraryRequest,
  ): Observable<fromApi.GetCharterTripItineraryResponse> {
    return this.baseApi.post('getCharterTripItinerary', { ...request });
  }

  public getCharterTripPurpose(): Observable<fromApi.GetCharterTripPurposeResponse> {
    return this.baseApi.post('getCharterTripPurpose');
  }

  public getCharterTripInvoice(
    request: fromApi.GetCharterTripInvoiceRequest,
  ): Observable<fromApi.GetCharterTripInvoiceResponse> {
    return this.baseApi.post('getCharterTripInvoice', { ...request });
  }

  public getCharterTripQuote(
    request: fromApi.GetCharterTripQuoteRequest,
  ): Observable<fromApi.GetCharterTripQuoteResponse> {
    return this.baseApi.post('getCharterTripQuote', { ...request });
  }

  public getDistrict(request: fromApi.GetDistrictRequest): Observable<fromApi.GetDistrictResponse> {
    return this.baseApi.post('getDistrict', request, this.portalEndpoint);
  }

  public getDriver(request: fromApi.GetDriverRequest): Observable<fromApi.GetDriverResponse> {
    return this.baseApi.post('getDriver', request, this.portalEndpoint);
  }

  public getGeneralContractOrganizationNames(): Observable<fromApi.GetGeneralContractOrganizationNamesResponse> {
    return this.baseApi.post('getGeneralContractOrganizationNames');
  }

  public getSchoolEmployee(request: fromApi.GetSchoolEmployeeRequest): Observable<fromApi.GetSchoolEmployeeResponse> {
    return this.baseApi.post('getSchoolEmployee', { ...request }, this.portalEndpoint);
  }

  public getVehicle(request: fromApi.GetVehicleRequest): Observable<fromApi.GetVehicleResponse> {
    return this.baseApi.post('getVehicle', request, this.portalEndpoint);
  }

  public updateCharterCustomer(
    request: fromApi.UpdateCharterCustomerRequest,
  ): Observable<fromApi.UpdateCharterCustomerResponse> {
    return this.baseApi.post('updateCharterCustomer', { ...request });
  }

  public updateCharterTripDetails(
    request: fromApi.UpdateCharterTripDetailsRequest,
  ): Observable<fromApi.UpdateCharterTripDetailsResponse> {
    return this.baseApi.post('updateCharterTripDetails', { ...request });
  }

  public updateCharterTripQuote(
    request: fromApi.UpdateCharterTripQuoteRequest,
  ): Observable<fromApi.UpdateCharterTripQuoteResponse> {
    return this.baseApi.post('updateCharterTripQuote', { ...request });
  }

  public validateCharterTripItinerary(
    request: fromApi.ValidateCharterTripItineraryRequest,
  ): Observable<fromApi.ValidateCharterTripItineraryResponse> {
    return this.baseApi.post('validateCharterTripItinerary', { ...request });
  }
}
