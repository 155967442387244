import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getAccountFailed, getAccountRequested, getAccountSuccess } from '../actions';
import { of, switchMap } from 'rxjs';
import { ApiService } from '../../../core/services';
import { catchError, map } from 'rxjs/operators';
import { WpError } from '@rootTypes';

@Injectable()
export class AccountEffects {
  constructor(private actions$: Actions, private api: ApiService) {}

  public getAccountRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAccountRequested),
      switchMap(() => {
        return this.api.getAccount().pipe(
          map((resp) => getAccountSuccess({ response: resp })),
          catchError((originalError) => {
            console.log(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to get account',
            };
            return of(getAccountFailed({ error }));
          }),
        );
      }),
    );
  });
}
