import { TripEditorStopsForm, TripEditorLeg, TripEditorLegName, TripEditorLegStop } from '../entities';
import { getStopsFromTripEditorLeg } from './get-stops-from-trip-editor-leg';
import { generateTripEditorStopId } from './generate-trip-editor-stop-id';
import { isTripEditorStopValid } from './is-trip-editor-stop-valid';

export const autofillReturnLeg = (state: TripEditorStopsForm): TripEditorStopsForm => {
  const returnLeg: TripEditorLeg = {
    legName: TripEditorLegName.RETURN,
    isReturnLeg: true,
    isReadyForDirectionRequest: false,
    summary: { isLoading: false },
    stops: {},
  };

  const outboundLeg = state[TripEditorLegName.OUTBOUND];
  const outboundStops = getStopsFromTripEditorLeg(outboundLeg);
  outboundStops.reverse().forEach((outboundStop, index) => {
    const stop: TripEditorLegStop = {
      stopId: generateTripEditorStopId(),
      stopIndex: index,
      isFirst: index === 0,
      isLast: index === outboundStops.length - 1,
      data: {
        address: { ...outboundStop.data.address },
        arrivalDate: null,
        arrivalTime: null,
        departureTime: null,
      },
      isValid: undefined,
    };
    if (stop.isFirst) {
      stop.data.arrivalDate = outboundStop.data.arrivalDate;
      stop.data.arrivalTime = outboundStop.data.arrivalTime;
    }
    stop.isValid = isTripEditorStopValid(stop);
    returnLeg.stops[stop.stopId] = stop;
  });

  return {
    ...state,
    [TripEditorLegName.RETURN]: returnLeg,
  };
};
