import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Observable, Subscription, throttleTime } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { isMatchedWithSearchTerm } from '@rootTypes/utils';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

interface PreparedOption {
  original: string;
  prepared: string;
}

@Component({
  selector: 'wp-autocomplete-text',
  templateUrl: './autocomplete-text.component.html',
  styleUrls: ['./autocomplete-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteTextComponent implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public control: FormControl;
  @Input() public options: string[];
  @Input() public controlStateChange?: Observable<void>;
  @Input() public requiredErrorText = 'Required field';
  @Input() public isOptionsShownOnFocus = true;
  /**
   * Parent element to listen to close the dropdown.
   * Note, this is required when using the component inside a modal,
   * in other cases the dropdown is closing normally.
   */
  @Input() public parentSelectorToCloseByClick?: string;

  @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger })
  private autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('autocomplete')
  private autocomplete: MatAutocomplete;

  public searchedOptions$: Observable<PreparedOption[]>;
  private preparedOptions: PreparedOption[];

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setPreparedOptions();

    if (this.isOptionsShownOnFocus) {
      this.searchedOptions$ = this.control.valueChanges.pipe(
        distinctUntilChanged(),
        throttleTime(300, undefined, { leading: false, trailing: true }),
        startWith(this.control.value),
        map((text) => this.search(text)),
      );
    } else {
      this.searchedOptions$ = this.control.valueChanges.pipe(
        distinctUntilChanged(),
        throttleTime(300, undefined, { leading: false, trailing: true }),
        map((text) => this.search(text)),
      );
    }

    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(controlStateSub);
    }

    if (this.parentSelectorToCloseByClick) {
      const onClickOutside = fromEvent(document.querySelector(this.parentSelectorToCloseByClick), 'click').subscribe(
        () => {
          if (this.autocomplete && this.autocomplete.isOpen) {
            this.autocompleteTrigger.closePanel();
          }
        },
      );
      this.sub.add(onClickOutside);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private setPreparedOptions(): void {
    this.preparedOptions = (this.options || []).map((text) => {
      return {
        original: text,
        prepared: text.toLowerCase(),
      };
    });
  }

  private search(text: string): PreparedOption[] {
    if (!text) {
      return [...this.preparedOptions];
    }
    const searchTerm = text.toLowerCase();
    return this.preparedOptions.filter((opt) => {
      return isMatchedWithSearchTerm(opt.prepared, searchTerm);
    });
  }
}
