import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, take } from 'rxjs/operators';
import * as fromTypes from '@rootTypes';
import { DropdownComponent } from '../dropdown/dropdown/dropdown.component';
import { YearMonthDay } from '@rootTypes/utils/common/date';
import { getDistanceFromWindowBottom } from '@rootTypes/utils/common/dom';
import { YYYYMMDDString } from '../multi-date-picker/types/entities/common';

/**
 * Input control for single date selection. Binds to formControl of YYYYMMDD formatted string
 */
@Component({
  selector: 'wp-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public control: FormControl = new FormControl();
  @Input() public controlType: 'yyyymmdd' | 'date' = 'yyyymmdd';
  @Input() public isIcon: boolean;
  @Input() public monthCount = 2;
  @Input() public displayDateFormat = 'ddd MMM DD, YYYY';
  @Input() public alignDropdown: 'left' | 'right' | 'center';
  @Input() public closeOnSelect: boolean;
  @Input() public disableDatesBefore: fromTypes.YYYYMMDDString;
  @Input() public disableDatesAfter: fromTypes.YYYYMMDDString;
  @Input() public widthStr = 'auto';
  @Input() public tabIndex = '-1';
  /**
   * If the trigger is less than this distance from viewport bottom,
   * will display the datepicker at the top of the input
   */
  @Input() public dropdownNearBottomThreshold = 300;

  @Output() public valueChangedByUser = new EventEmitter<YYYYMMDDString | Date>();

  public dropdownVerticalAlignment: 'top' | 'bottom' = 'bottom';
  public dropdownVerticalOffset = 'calc(100% - 23px)';
  public selectedDate$: Observable<fromTypes.YYYYMMDDString>;
  public selectedDateArr$: BehaviorSubject<fromTypes.YYYYMMDDString[]> = new BehaviorSubject([]);
  public displayDateControl = new FormControl();
  public focused$: Observable<boolean>;
  public isDisabled$: Observable<boolean>;
  public highlighted$: Observable<boolean>;
  public subscripitions = new Subscription();

  private focused$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild('maskEl') private triggerMaskEl: ElementRef;
  @ViewChild('inputElWrap') private inputEl: ElementRef;
  @ViewChild('dropdownEl') private dropdownEl: DropdownComponent;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.subscripitions.unsubscribe();
  }

  ngOnInit(): void {
    this.checkInputFormat();
    this.highlighted$ = this.getHighLightedObs();
    this.isDisabled$ = this.getDisabledObs$();
    this.selectedDate$ = this.control.valueChanges.pipe(
      startWith(this.control?.value),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
    const dateToArraySub = this.selectedDate$.subscribe((date) => {
      this.selectedDateArr$.next(date ? [date] : []);
    });
    this.subscripitions.add(dateToArraySub);
    if (this.control) {
      const sub = this.control.valueChanges.pipe(startWith(this.control.value)).subscribe((val) => {
        if (val) {
          const yyyyMMDD = this.getYYYYMMDDValue(val);
          const formatter = new YearMonthDay(yyyyMMDD);
          this.displayDateControl.setValue(formatter.toMoment().format(this.displayDateFormat));
        } else {
          this.displayDateControl.setValue(null);
        }
      });
      const sub2 = this.control.statusChanges.subscribe((status) => {
        if (status === 'INVALID') {
          this.displayDateControl.setErrors(this.control.errors);
          this.displayDateControl.markAsTouched();
          this.cd.detectChanges();
        } else {
          this.displayDateControl.setErrors(null);
          this.displayDateControl.updateValueAndValidity();
        }
      });
      const subDisabled = this.isDisabled$.pipe(distinctUntilChanged()).subscribe((isDisabled) => {
        if (isDisabled) {
          this.displayDateControl.disable();
        } else {
          this.displayDateControl.enable();
        }
      });
      this.subscripitions.add(sub);
      this.subscripitions.add(sub2);
      this.subscripitions.add(subDisabled);
    }
  }

  public onTriggerClick(event?: MouseEvent): void {
    this.isDisabled$.pipe(take(1)).subscribe((disabled) => {
      if (!disabled) {
        this.focused$$.next(true);
        this.displayDateControl.markAsTouched();
        this.setDropdownVerticalAlignment();
      } else {
        if (event) {
          event.stopPropagation();
        }
      }
    });
  }

  public onCloseDropdown(): void {
    this.focused$$.next(false);
    this.control.markAsTouched();
    this.displayDateControl.updateValueAndValidity();
    this.control.updateValueAndValidity();
  }

  public onApplyClicked(): void {
    if (this.dropdownEl) {
      this.dropdownEl.onCloseDropdown();
    }
  }

  public onValueChangedByUserAction(val: fromTypes.YYYYMMDDString[]): void {
    const sourceValue = (val || [])[0];
    const targetValue = this.getControlValueFromYYYYMMDD(sourceValue);
    this.control.setValue(targetValue);
    this.valueChangedByUser.emit(targetValue);
    if (this.closeOnSelect) {
      this.onApplyClicked();
    }
  }

  private getHighLightedObs(): Observable<boolean> {
    const focused$ = this.focused$$.asObservable();
    const valid$ = this.control?.statusChanges.pipe(
      startWith('VALID'),
      map((status) => status !== 'INVALID'),
    );
    return combineLatest([focused$, valid$]).pipe(map(([focused, valid]) => focused && valid));
  }

  private checkInputFormat(): void {
    const value = this.control?.value;
    if (value !== null && value !== undefined) {
      if (value instanceof Date) {
        return;
      }
      if (!(typeof value === 'string')) {
        throw new Error(
          'Invalid value provided to input date. Should be YYYYMMDD string. Got ' + typeof value + ' instead.',
        );
      }
      if (!(value.length === 8)) {
        throw new Error('Invalid string provided to input date. Should be YYYYMMDD string. Got ' + value + ' instead.');
      }
    }
  }

  private getDisabledObs$(): Observable<boolean> {
    return timer(0, 600).pipe(
      map(() => this.control.disabled),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getYYYYMMDDValue(source: fromTypes.YYYYMMDDString | Date): fromTypes.YYYYMMDDString {
    if (this.controlType === 'yyyymmdd') {
      return source as fromTypes.YYYYMMDDString;
    } else {
      return YearMonthDay.fromDate(source as Date);
    }
  }

  private getControlValueFromYYYYMMDD(source: fromTypes.YYYYMMDDString): Date | fromTypes.YYYYMMDDString {
    if (!source) {
      return undefined;
    }
    if (this.controlType === 'yyyymmdd') {
      return source;
    } else {
      const formatter = new YearMonthDay(source);
      return formatter.toMoment().toDate();
    }
  }

  private setDropdownVerticalAlignment(): void {
    const distanceFromBottom = getDistanceFromWindowBottom(this.inputEl.nativeElement);
    if (distanceFromBottom > this.dropdownNearBottomThreshold) {
      this.dropdownVerticalAlignment = 'bottom';
      this.dropdownVerticalOffset = 'calc(100% - 23px)';
    } else {
      this.dropdownVerticalAlignment = 'top';
      this.dropdownVerticalOffset = 'calc(100% - 8px)';
    }
    this.cd.detectChanges();
  }

  onClearInput() {
    this.control.setValue(undefined);
    this.valueChangedByUser.emit(undefined);
    if (this.dropdownEl) {
      this.dropdownEl.onCloseDropdown();
    }
  }
}
