import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { of, combineLatest } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ApiService } from 'src/app/core/services';
import * as fromActions from './trip-editor-stops.actions';
import * as fromSelectors from './trip-editor-stops.selectors';
import {
  GetCharterDirectionsRequest,
  ValidateCharterTripItineraryRegionDetected,
  ValidateCharterTripItineraryRequest,
} from '@rootTypes/api';
import {
  getInBetweenStops,
  getStopsFromTripEditorLeg,
  isTripEditorLegValid,
  TripEditorLeg,
  TripEditorLegName,
} from './types';
import { tripEditorGetTripItinerarySuccess } from '../trip-editor-itinerary/trip-editor-itinerary.actions';
import { selectTripCustomer } from '../trip-editor-customer/trip-editor-customer.selectors';
import { selectTripEditorItinerary } from '../trip-editor-itinerary/trip-editor-itinerary.selectors';
import { PopupService } from '../../../../core/popup/popup.service';
import { selectTripEditorIds } from '../trip-editor-ids/trip-editor-ids.selectors';

@Injectable()
export class TripEditorStopsEffects {
  public stopInputChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromActions.tripEditorLegAddressChanged,
        fromActions.tripEditorLegArrivalDateChanged,
        fromActions.tripEditorLegArrivalTimeChanged,
        fromActions.tripEditorLegDepartureTimeChanged,
      ),
      concatLatestFrom(({ ref }) => this.store.select(fromSelectors.selectTripEditorLeg(ref.legName))),
      filter(([, leg]) => leg.isReadyForDirectionRequest),
      debounceTime(300),
      map(([, leg]) => this.createGetDirectionRequestedAction(leg)),
    );
  });

  public stopRemoved$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorLegStopRemoved),
      concatLatestFrom(({ ref }) => this.store.select(fromSelectors.selectTripEditorLeg(ref.legName))),
      filter(([, leg]) => leg.isReadyForDirectionRequest),
      map(([, leg]) => this.createGetDirectionRequestedAction(leg)),
    );
  });

  public returnLegAutofilledOnOutboundLegReady$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorStopsGetDirectionSuccess),
      concatLatestFrom(() => this.store.select(fromSelectors.selectTripEditorStopsForm)),
      filter(([{ legName }, form]) => {
        const changedLeg = form[legName];
        return form.isRoundTrip && !changedLeg.isReturnLeg && isTripEditorLegValid(changedLeg);
      }),
      map(() => fromActions.tripEditorStopsReturnLegAutofilled()),
    );
  });

  public returnLegAutofilledOnTripTypeChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorStopsTripTypeChanged),
      concatLatestFrom(() => this.store.select(fromSelectors.selectTripEditorStopsForm)),
      filter(([, form]) => {
        const outboundLeg = form[TripEditorLegName.OUTBOUND];
        return form.isRoundTrip && isTripEditorLegValid(outboundLeg);
      }),
      map(() => fromActions.tripEditorStopsReturnLegAutofilled()),
    );
  });

  public directionRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorStopsGetDirectionRequested),
      switchMap(({ legName, request }) => {
        return this.api.getCharterDirections(request).pipe(
          map((res) => {
            return fromActions.tripEditorStopsGetDirectionSuccess({
              legName: legName,
              direction: res,
            });
          }),
          catchError((originalError) => {
            return of(
              fromActions.tripEditorStopsGetDirectionFailed({
                legName: legName,
                error: {
                  text: 'Failed to calculate the route',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public validateItineraryRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.validateTripItineraryRequested),
      concatLatestFrom(() =>
        combineLatest([
          this.store.select(fromSelectors.selectTripEditorStopsState),
          this.store.select(fromSelectors.selectTripEditorStops),
          this.store.select(selectTripCustomer),
          this.store.select(selectTripEditorIds),
        ]).pipe(take(1)),
      ),
      switchMap(([, [state, stops, tripCustomer, ids]]) => {
        const { isKeepDriverForDuration, isWheelChairAccessible, numberOfPassengers, tripPurpose, isRoundTrip } =
          state.form;

        const request: ValidateCharterTripItineraryRequest = {
          districtId: tripCustomer.organization.id,
          isKeepDriverForDuration,
          isWheelChairAccessible,
          numberOfPassengers,
          tripPurpose,
          outboundStops: stops.outboundStops,
        };
        if (isRoundTrip) {
          request.returnStops = stops.returnStops;
        }
        if (tripCustomer.branch) {
          request.campusId = tripCustomer.branch.id;
        }
        if (ids.tripId) {
          request.tripId = ids.tripId;
        }

        return this.api.validateCharterTripItinerary(request).pipe(
          map((response) => {
            return fromActions.validateTripItinerarySuccess({
              response: response as ValidateCharterTripItineraryRegionDetected,
            });
          }),
          catchError((originalError) => {
            return of(
              fromActions.validateTripItineraryFailed({
                error: {
                  text: 'Failed to validate trip itinerary',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public validateTripItineraryFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.validateTripItineraryFailed),
        tap(({ error }) => {
          this.popupService.openErrorPopup(error);
        }),
      );
    },
    { dispatch: false },
  );

  public loadItinerarySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(tripEditorGetTripItinerarySuccess),
      map(({ response }) => fromActions.tripEditorStopsSetFormFromItinerary({ itinerary: response })),
    );
  });

  public collapseStopsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.tripEditorStopsTopBarCollapseStopsRequested),
      concatLatestFrom(() => this.store.select(selectTripEditorItinerary)),
      map(([, itinerary]) => fromActions.tripEditorStopsSetFormFromItinerary({ itinerary })),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private api: ApiService,
    private popupService: PopupService,
  ) {}

  private createGetDirectionRequestedAction(leg: TripEditorLeg): Action {
    const stops = getStopsFromTripEditorLeg(leg);
    const lastStop = stops[stops.length - 1].data;
    const inBetweenStops = getInBetweenStops(stops).map((stop) => {
      const { address, arrivalTime, departureTime } = stop.data;
      return {
        address,
        arrivalTime,
        departureTime,
      };
    });
    const request: GetCharterDirectionsRequest = {
      stops: {
        firstStop: { ...stops[0].data },
        inbetweenStops: inBetweenStops,
        lastStop: {
          address: lastStop.address ? { ...lastStop.address } : null,
        },
      },
    };
    return fromActions.tripEditorStopsGetDirectionRequested({
      legName: leg.legName,
      request,
    });
  }
}
