import { createInitialTripEditorLeg } from './create-initial-trip-editor-leg';
import { TripEditorLegName, TripEditorStopsState } from '../entities';

export const createInitialTripEditorStopsState = (): TripEditorStopsState => {
  return {
    form: {
      isRoundTrip: true,
      isWheelChairAccessible: false,
      isKeepDriverForDuration: false,
      [TripEditorLegName.OUTBOUND]: createInitialTripEditorLeg(false),
      [TripEditorLegName.RETURN]: createInitialTripEditorLeg(true),
    },
    validateTripItinerary: { isLoading: false },
  };
};
