import { NgModule } from '@angular/core';
import { routerReducer, RouterReducerState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { routerFeatureKey } from './router/feature-key';
import { CustomRouterStateSerializer } from '../routes/custom-router-state-serializer';
import { TripEditorStoreModule } from '../shared/trip-editor/store/trip-editor-store.module';
import { tripDataFeatureKey } from './trip-data/feature-key';
import { tripDataReducer, TripDataState } from './trip-data/reducer';
import { TripDataEffects } from './trip-data/trip-data.effects';
import { appConfigFeatureKey } from './app-config/feature-key';
import { appConfigReducer, AppConfigState } from './app-config/reducer';
import { AppConfigEffects } from './app-config/app-config.effects';
import { entitiesDataFeatureKey } from './entities-data/feature-key';
import { entitiesDataReducer, EntitiesDataState } from './entities-data/reducer';
import { EntitiesDataEffects } from './entities-data/entities-data.effects';
import { authFeatureKey } from './auth/feature-key';
import { authReducer, AuthState } from './auth/reducer';
import { authEffects } from './auth/effects';

interface RootState {
  [authFeatureKey]: AuthState;
  [appConfigFeatureKey]: AppConfigState;
  [entitiesDataFeatureKey]: EntitiesDataState;
  [routerFeatureKey]: RouterReducerState;
  [tripDataFeatureKey]: TripDataState;
}

const rootReducers: ActionReducerMap<RootState> = {
  [authFeatureKey]: authReducer,
  [appConfigFeatureKey]: appConfigReducer,
  [entitiesDataFeatureKey]: entitiesDataReducer,
  [routerFeatureKey]: routerReducer,
  [tripDataFeatureKey]: tripDataReducer,
};

@NgModule({
  imports: [
    StoreModule.forRoot(rootReducers, {}),
    EffectsModule.forRoot([TripDataEffects, AppConfigEffects, EntitiesDataEffects, ...authEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: cEnvironment.envName !== 'production',
    }),
    TripEditorStoreModule,
  ],
})
export class AppStoreModule {}
