import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'wp-field-label-side-value',
  templateUrl: './field-label-side-value.component.html',
  styleUrls: ['./field-label-side-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldLabelSideValueComponent implements OnInit, OnChanges {
  @Input() public label: string;
  @Input() public value: string | number;
  /**
   * If non, displays long dash
   */
  @Input() public defaultValue = '--';
  @Input() public isHardCoded: boolean;

  public valueHTML: SafeHtml;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.defaultValue) {
      this.valueHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.value || this.defaultValue);
    }
  }
}
