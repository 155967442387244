<ng-container *ngIf="viewType === 'full'; else reducedView">
  <div
    class="result-bar-full-wrap"
    [ngClass]="{ 'hidden': itemsCountLoading || (totalFound === null || totalFound === undefined) }"
  >
    <wp-list-result-bar-full
      [entityName]="entityName"
      [totalFound]="totalFound"
      [customPluralForm]="customPluralForm"
      [pageSize]="pageSize"
      [currentPage]="currentPage"
      [maxQuantity]="maxQuantity"
      [centerContent]="centerContent"
    ></wp-list-result-bar-full>
  </div>
</ng-container>

<ng-template #reducedView>
  <div
    class="result-bar-reduced-wrap"
    [ngClass]="{ 'hidden': itemsCountLoading || (totalFound === null || totalFound === undefined) }"
  >
    <span style="font-weight: 600">
      {{ totalFound | displayQuantity:entityName:customPluralForm }} found
    </span>
  </div>
</ng-template>
