import { createReducer, on } from '@ngrx/store';
import * as fromActions from './trip-editor-stops.actions';
import {
  TripEditorStopsState,
  TripEditorLeg,
  TripEditorLegName,
  createInitialTripEditorStopsState,
  createInitialTripEditorLeg,
  createTripEditorStopsFormFromItinerary,
  onTripEditorTimeChanged,
  updateTripEditorLegOnDirectionSuccess,
  addTripEditorStop,
  removeTripEditorStop,
  autofillReturnLeg,
  onTripEditorAddressChanged,
  onTripEditorArrivalDateChanged,
} from './types';
import { createEntityState } from '@rootTypes/utils';
import { createNewTripSuccess, initializeNewTrip } from '../create-trip/create-trip.actions';

export const tripEditorStopsReducer = createReducer<TripEditorStopsState>(
  createInitialTripEditorStopsState(),
  on(initializeNewTrip, createNewTripSuccess, () => createInitialTripEditorStopsState()),
  on(fromActions.tripEditorStopsTripTypeChanged, (state, { isRoundTrip }): TripEditorStopsState => {
    if (isRoundTrip) {
      return {
        ...state,
        form: {
          ...state.form,
          isRoundTrip,
          [TripEditorLegName.RETURN]: createInitialTripEditorLeg(true),
          isKeepDriverForDuration: false,
        },
      };
    }
    return {
      ...state,
      form: {
        ...state.form,
        isRoundTrip,
        [TripEditorLegName.RETURN]: undefined,
        isKeepDriverForDuration: false,
      },
    };
  }),
  on(fromActions.tripEditorLegAddressChanged, (state, { ref, value }): TripEditorStopsState => {
    return onTripEditorAddressChanged(state, ref, value);
  }),
  on(fromActions.tripEditorLegArrivalDateChanged, (state, { ref, value }): TripEditorStopsState => {
    return onTripEditorArrivalDateChanged(state, ref, value);
  }),
  on(fromActions.tripEditorLegArrivalTimeChanged, (state, { ref, value }): TripEditorStopsState => {
    return onTripEditorTimeChanged(state, 'arrivalTime', ref, value);
  }),
  on(fromActions.tripEditorLegDepartureTimeChanged, (state, { ref, value }): TripEditorStopsState => {
    return onTripEditorTimeChanged(state, 'departureTime', ref, value);
  }),
  on(fromActions.tripEditorLegStopAdded, (state, { legName }): TripEditorStopsState => {
    return {
      ...state,
      form: {
        ...state.form,
        [legName]: addTripEditorStop(state.form[legName]),
      },
    };
  }),
  on(fromActions.tripEditorLegStopRemoved, (state, { ref }): TripEditorStopsState => {
    return {
      ...state,
      form: {
        ...state.form,
        [ref.legName]: removeTripEditorStop(state.form[ref.legName], ref.stopId),
      },
    };
  }),
  on(fromActions.tripEditorStopsReturnLegAutofilled, (state): TripEditorStopsState => {
    return {
      ...state,
      form: autofillReturnLeg(state.form),
    };
  }),
  on(fromActions.tripEditorStopsGetDirectionRequested, (state, { legName }): TripEditorStopsState => {
    const leg: TripEditorLeg = state.form[legName];
    return {
      ...state,
      form: {
        ...state.form,
        [legName]: {
          ...leg,
          summary: { isLoading: true },
        },
      },
    };
  }),
  on(fromActions.tripEditorStopsGetDirectionSuccess, (state, { legName, direction }): TripEditorStopsState => {
    const leg: TripEditorLeg = state.form[legName];
    return {
      ...state,
      form: {
        ...state.form,
        [legName]: updateTripEditorLegOnDirectionSuccess(leg, direction),
      },
    };
  }),
  on(fromActions.tripEditorStopsGetDirectionFailed, (state, { legName, error }): TripEditorStopsState => {
    const leg: TripEditorLeg = state.form[legName];
    return {
      ...state,
      form: {
        ...state.form,
        [legName]: {
          ...leg,
          summary: createEntityState(undefined, error),
        },
      },
    };
  }),
  on(fromActions.tripEditorStopsPassengerCountChanged, (state, { value }): TripEditorStopsState => {
    return {
      ...state,
      form: {
        ...state.form,
        numberOfPassengers: value,
      },
    };
  }),
  on(fromActions.tripEditorStopsWheelchairAccessChanged, (state, { value }): TripEditorStopsState => {
    return {
      ...state,
      form: {
        ...state.form,
        isWheelChairAccessible: value,
      },
    };
  }),
  on(fromActions.tripEditorLegKeepDriverChanged, (state, { isKeepDriverForDuration }): TripEditorStopsState => {
    return {
      ...state,
      form: {
        ...state.form,
        isKeepDriverForDuration,
      },
    };
  }),
  on(fromActions.tripEditorStopsTripPurposeChanged, (state, { tripPurpose }): TripEditorStopsState => {
    return {
      ...state,
      form: {
        ...state.form,
        tripPurpose,
      },
    };
  }),
  on(fromActions.validateTripItineraryRequested, (state): TripEditorStopsState => {
    return {
      ...state,
      validateTripItinerary: { isLoading: true },
    };
  }),
  on(fromActions.validateTripItinerarySuccess, (state, { response }): TripEditorStopsState => {
    return {
      ...state,
      validateTripItinerary: {
        isLoading: true, // don't stop loading indicator for better UI
        entity: response,
      },
    };
  }),
  on(fromActions.destroyTripEditorStops, (state): TripEditorStopsState => {
    return {
      ...state,
      validateTripItinerary: {
        isLoading: false,
      },
    };
  }),
  on(fromActions.validateTripItineraryFailed, (state, { error }): TripEditorStopsState => {
    return {
      ...state,
      validateTripItinerary: {
        isLoading: false, // don't stop loading indicator for better UI
        error,
      },
    };
  }),
  on(fromActions.tripEditorStopsSetFormFromItinerary, (state, { itinerary }): TripEditorStopsState => {
    return {
      ...state,
      form: createTripEditorStopsFormFromItinerary(itinerary),
    };
  }),
);
