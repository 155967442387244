import { EntityState } from '@rootTypes';
import { GetAccountResponse } from '@rootTypes/api/get-account';
import { createReducer, on } from '@ngrx/store';
import { getAccountFailed, getAccountRequested, getAccountSuccess } from './actions';

export interface AuthState {
  account: EntityState<GetAccountResponse> & { resolved: boolean };
}

export const getInitialAuthState = (): AuthState => {
  return { account: { isLoading: false, resolved: false } };
};

export const authReducer = createReducer<AuthState>(
  getInitialAuthState(),
  on(getAccountRequested, (state, action) => {
    return {
      ...state,
      account: {
        isLoading: true,
        resolved: false,
      },
    };
  }),
  on(getAccountSuccess, (state, action) => {
    return {
      ...state,
      account: {
        isLoading: false,
        entity: action.response,
        resolved: true,
      },
    };
  }),
  on(getAccountFailed, (state, action) => {
    return {
      ...state,
      account: {
        isLoading: false,
        error: action.error,
        resolved: true,
      },
    };
  }),
);
