import { createSelector } from '@ngrx/store';
import { selectTripEditorState } from '../trip-editor-feature.selector';

const selectTripEditorContractState = createSelector(selectTripEditorState, (state) => state.contract);

export const selectTripEditorContractLoading = createSelector(
  selectTripEditorContractState,
  (state) => state.isLoading,
);
export const selectTripEditorContract = createSelector(selectTripEditorContractState, (state) => state.data);
export const selectTripEditorCreditCardFeePercent = createSelector(
  selectTripEditorContract,
  (state) => state?.creditCardFeePercent,
);
export const selectTripEditorContractError = createSelector(selectTripEditorContractState, (state) => state.error);

export const selectTripEditorRentalPolicy = createSelector(
  selectTripEditorContract,
  (state) => state?.rentalPolicy || [],
);
export const selectTripEditorCancellationPolicy = createSelector(
  selectTripEditorContract,
  (state) => state?.cancellationPolicy,
);
export const selectIsGeneralContract = createSelector(selectTripEditorContract, (state) => state?.isGeneralContract);

export const selectTripEditorContractCustomFields = createSelector(
  selectTripEditorContract,
  (state) => state?.customFields,
);
