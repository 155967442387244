import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'wp-list-result-bar-full',
  templateUrl: './list-result-bar-full.component.html',
  styleUrls: ['./list-result-bar-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListResultBarFullComponent implements OnInit {
  @Input() entityName = 'item';
  @Input() public customPluralForm: string;
  @Input() maxQuantity = 500;
  @Input() public totalFound: number;
  @Input() public pageSize: number;
  @Input() public currentPage: number;
  @Input() public centerContent?: TemplateRef<any>;

  public showingFrom: number;
  public showingTo: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalFound || changes.pageSize || changes.currentPage) {
      this.updatePageLimits();
    }
  }

  ngOnInit(): void {}

  private updatePageLimits(): void {
    this.showingFrom = this.getShowingFrom(this.pageSize, this.currentPage);
    this.showingTo = this.getShowingTo(this.pageSize, this.currentPage, this.totalFound);
  }

  private getShowingFrom(pageSize: number, currentPage: number): number {
    return currentPage * pageSize + 1;
  }

  private getShowingTo(pageSize: number, currentPage: number, totalItemsFound: number): number {
    return Math.min(currentPage * pageSize + pageSize, totalItemsFound);
  }
}
