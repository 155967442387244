import { EntityState } from '@rootTypes';
import { GetCharterConfigResponse } from '@rootTypes/api';
import { createReducer, on } from '@ngrx/store';
import { getCharterConfigFailed, getCharterConfigRequested, getCharterConfigSuccess } from './actions';

export type AppConfigState = EntityState<GetCharterConfigResponse> & { resolved: boolean };

export const getInitialAppConfigState = (): AppConfigState => ({
  entity: undefined,
  error: undefined,
  isLoading: false,
  resolved: false,
});

export const appConfigReducer = createReducer<AppConfigState>(
  getInitialAppConfigState(),
  on(getCharterConfigRequested, (state, action): AppConfigState => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(getCharterConfigSuccess, (state, action): AppConfigState => {
    return {
      ...state,
      isLoading: false,
      entity: action.data,
      resolved: true,
    };
  }),
  on(getCharterConfigFailed, (state, action): AppConfigState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
      resolved: true,
    };
  }),
);
