<c-page-header></c-page-header>
<c-home-content>
  <div class="content">
    <div class="title">User is not authorized</div>
    <div class="instructions">
      Please sign in to Admin Portal to access this site.
    </div>
    <wp-link [href]="homeLink" [isUnderlined]="true" [fontSize]="'16px'">
      Try again
    </wp-link>
  </div>
</c-home-content>
<c-page-footer></c-page-footer>
