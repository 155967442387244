import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCharterConfigRequested } from '../../store/app-config/actions';
import { isAppConfigResolved } from '../../store/app-config/selectors';
import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppConfigInitGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    this.store.dispatch(getCharterConfigRequested());
    return this.store.select(isAppConfigResolved).pipe(
      filter((resolved) => !!resolved),
      take(1),
      map(() => true),
    );
  }
}
