import { createSelector } from '@ngrx/store';
import { getAuthFeature } from './feature-selector';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';

const getAccountAPI = createSelector(getAuthFeature, (state) => state.account);
export const isAccountLoading = createSelector(getAccountAPI, (state) => state?.isLoading || false);
export const isAccountResolved = createSelector(getAccountAPI, (state) => state?.resolved || false);
export const getAccount = createSelector(getAccountAPI, (state) => state.entity);
export const getAccountError = createSelector(getAccountAPI, (state) => state.error);

export const isLoggedIn = createSelector(getAccount, (acc) => !!acc);
export const getAccountPermissions = createSelector(getAccount, (state) => state?.permissions || []);

export const hasPermission = (permission: CharterPermissions) =>
  createSelector(getAccountPermissions, (permissions) => permissions.some((p) => p === permission));

export const isCharterTripBookingEditPermission = hasPermission(CharterPermissions.CHARTER_TRIP_BOOKING_EDIT);
