import { createSelector, MemoizedSelector } from '@ngrx/store';

import { WpError, TripStops } from '@rootTypes';
import {
  getInBetweenStops,
  getStopsFromTripEditorLeg,
  isTripEditorLegValid,
  TripEditorLegName,
  TripEditorLegStop,
  TripEditorLeg,
  TripEditorLegSummary,
} from './types';
import { selectTripEditorState } from '../trip-editor-feature.selector';

export const selectTripEditorStopsState = createSelector(selectTripEditorState, (state) => state.stops);

export const selectTripEditorStopsForm = createSelector(selectTripEditorStopsState, (state) => state.form);
export const selectIsRoundTrip = createSelector(selectTripEditorStopsForm, (state) => state.isRoundTrip);
export const selectPassengersCount = createSelector(selectTripEditorStopsForm, (state) => state.numberOfPassengers);
export const selectIsWheelchair = createSelector(selectTripEditorStopsForm, (state) => state.isWheelChairAccessible);
export const selectIsKeepDriver = createSelector(selectTripEditorStopsForm, (state) => state.isKeepDriverForDuration);
export const selectTripPurpose = createSelector(selectTripEditorStopsForm, (state) => state.tripPurpose);

export const selectTripEditorStopsStateValid = createSelector(selectTripEditorStopsForm, (state) => {
  const isOutboundLegValid = isTripEditorLegValid(state[TripEditorLegName.OUTBOUND]);
  if (!isOutboundLegValid) {
    return false;
  }
  let isReturnLegValid = true;
  if (state.isRoundTrip) {
    isReturnLegValid = isTripEditorLegValid(state[TripEditorLegName.RETURN]);
  }
  if (!isReturnLegValid) {
    return false;
  }
  return typeof state.numberOfPassengers === 'number' && state.numberOfPassengers > 0 && !!state.tripPurpose;
});

export const selectTripEditorStops = createSelector(selectTripEditorStopsForm, (state): TripStops => {
  const outboundStops = getStopsFromTripEditorLeg(state[TripEditorLegName.OUTBOUND]).map((stop) => stop.data);
  if (state.isRoundTrip) {
    return {
      outboundStops,
      returnStops: getStopsFromTripEditorLeg(state[TripEditorLegName.RETURN]).map((stop) => stop.data),
    };
  }
  return { outboundStops };
});

export const selectTripEditorLeg = (legName: TripEditorLegName): MemoizedSelector<any, TripEditorLeg> => {
  return createSelector(selectTripEditorStopsForm, (state) => {
    return state[legName];
  });
};

export const selectTripEditorLegStops = (legName: TripEditorLegName): MemoizedSelector<any, TripEditorLegStop[]> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return getStopsFromTripEditorLeg(state);
  });
};

export const selectTripEditorFirstStop = (legName: TripEditorLegName) =>
  createSelector(selectTripEditorLegStops(legName), (stops) => stops[0]);

export const selectTripEditorLastStop = (legName: TripEditorLegName) =>
  createSelector(selectTripEditorLegStops(legName), (stops) => stops[stops.length - 1]);

export const selectTripEditorInBetweenStops = (legName: TripEditorLegName) =>
  createSelector(selectTripEditorLegStops(legName), (stops) => getInBetweenStops(stops));

export const selectTripEditorLegSummary = (legName: TripEditorLegName): MemoizedSelector<any, TripEditorLegSummary> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return state?.summary?.entity;
  });
};

export const selectTripEditorLegSummaryLoading = (legName: TripEditorLegName): MemoizedSelector<any, boolean> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return state?.summary?.isLoading;
  });
};

export const selectTripEditorLegSummaryError = (legName: TripEditorLegName): MemoizedSelector<any, WpError> => {
  return createSelector(selectTripEditorLeg(legName), (state) => {
    return state?.summary?.error;
  });
};

const selectValidateTripItineraryState = createSelector(
  selectTripEditorStopsState,
  (state) => state.validateTripItinerary,
);
export const selectValidateTripItineraryLoading = createSelector(
  selectValidateTripItineraryState,
  (state) => state.isLoading,
);
export const selectValidateTripItineraryError = createSelector(
  selectValidateTripItineraryState,
  (state) => state.error,
);
export const selectValidateTripItineraryResult = createSelector(
  selectValidateTripItineraryState,
  (state) => state.entity,
);
