<div
  role="button"
  class="wp-btn-primary"
  [ngClass]="{ disabled: disabled, slim: slim, loading: loading, red: color === 'red' }"
  [ngStyle]="btnSize()"
  (click)="onClick($event)"
>
  <div *ngIf="loading; then loadingTemp; else loadedTemp"></div>

  <ng-template #loadingTemp>
    <div class="wp-btn-spinner">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </ng-template>
  <ng-template #loadedTemp>
    <div class="btn-content-wrap">
      <ng-content select="wp-btn-icon"> </ng-content>
      <ng-content> </ng-content>
      <ng-content select="wp-btn-suffix"></ng-content>
    </div>
  </ng-template>
</div>
