import { createAction, props } from '@ngrx/store';
import { WpError } from '@rootTypes';
import { GetAccountResponse } from '@rootTypes/api/get-account';

export const getAccountRequested = createAction('[Auth]: API getAccount requested');
export const getAccountSuccess = createAction(
  '[Auth]: API getAccount success',
  props<{ response: GetAccountResponse }>(),
);
export const getAccountFailed = createAction('[Auth]: API getAccount failed', props<{ error: WpError }>());
