import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { WpError } from '@rootTypes';
import { appLoadError, isAppLoading } from './store/app.selectors';

@Component({
  selector: 'c-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'charter';
  public isLoading$: Observable<boolean>;
  public error$: Observable<WpError>;

  constructor(private store: Store) {
    this.isLoading$ = this.store.select(isAppLoading);
    this.error$ = this.store.select(appLoadError);
  }
}
