import { createSelector } from '@ngrx/store';

import { selectTripEditorState } from '../trip-editor-feature.selector';
import { TripQuoteData, TripQuotePriceCorrectors, VehicleCatalog } from '@rootTypes';
import {
  selectTripEditorItineraryError,
  selectTripEditorItineraryLoading,
} from '../trip-editor-itinerary/trip-editor-itinerary.selectors';
import {
  selectTripEditorContract,
  selectTripEditorContractError,
  selectTripEditorContractLoading,
} from '../trip-editor-contract/trip-editor-contract.selectors';

export const selectTripEditorQuoteState = createSelector(selectTripEditorState, (state) => state.quote);

const selectCatalogsState = createSelector(selectTripEditorQuoteState, (state) => state.catalogs);
const selectCatalogsLoading = createSelector(selectCatalogsState, (state) => state.isLoading);
const selectCatalogsError = createSelector(selectCatalogsState, (state) => state.error);
const selectCatalogsEntity = createSelector(selectCatalogsState, (state) => state.entity);

export const selectTripEditorVehiclesPageLoading = createSelector(
  selectCatalogsLoading,
  selectTripEditorItineraryLoading,
  selectTripEditorContractLoading,
  (catalogsLoading, itineraryLoading, contractLoading) => catalogsLoading || itineraryLoading || contractLoading,
);
export const selectTripEditorVehiclesPageError = createSelector(
  selectCatalogsError,
  selectTripEditorItineraryError,
  selectTripEditorContractError,
  (catalogsError, itineraryError, contractError) => catalogsError || itineraryError || contractError,
);

export const selectCatalogsMap = createSelector(selectCatalogsEntity, (state) => {
  const result: { [id: string]: VehicleCatalog } = {};
  Object.values(state || {}).forEach(({ data }) => {
    result[data.catalogId] = data;
  });
  return result;
});
export const selectCatalogs = createSelector(selectCatalogsEntity, (state) => {
  return Object.values(state || {})
    .sort((a, b) => a.index - b.index)
    .map((vehicle) => vehicle.data);
});

const selectTripQuoteState = createSelector(selectTripEditorQuoteState, (state) => state.tripQuote);
export const selectTripQuote = createSelector(selectTripQuoteState, (state) => state.entity);
export const selectTripQuoteLoading = createSelector(selectTripQuoteState, (state) => state.isLoading);
export const selectTripQuoteError = createSelector(selectTripQuoteState, (state) => state.error);

export const selectTripEditorPriceCorrectors = createSelector(selectTripQuote, (quote): TripQuotePriceCorrectors => {
  if (!quote) {
    return undefined;
  }
  return {
    discounts: quote.discounts,
    additionalCharge: quote.additionalCharge,
  };
});

export const selectTripQuoteData = createSelector(selectTripQuote, selectTripEditorContract, (quote, contract) => {
  if (!quote || !contract) {
    return undefined;
  }
  const data: TripQuoteData = {
    quote,
    config: {
      discounts: contract.discounts,
      additionalCharge: contract.additionalCharge,
      creditCardFeePercent: contract.creditCardFeePercent,
    },
  };
  return data;
});

export const selectSelectedCatalogs = createSelector(selectTripEditorQuoteState, (state) =>
  Object.values(state.selectedCatalogs),
);

export const selectIsVehiclesPageReadyToProceed = createSelector(selectTripQuoteData, (data) => {
  return !!data && data.quote.catalogsItemizedQuote.length > 0;
});
