import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';

import { formatPhone } from '@rootTypes/utils';
import { PhoneInputErrorStateMatcher } from './phone-input-error-state-matcher';

@Component({
  selector: 'wp-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhoneComponent implements OnInit, OnDestroy {
  /**
   * Floating label. Will be hidden if omitted.
   */
  @Input() public label?: string;

  /**
   * FormControl with the unformatted value and validators
   * @example new FormControl('', [Validators.required, phoneValidator])
   */
  @Input() public control: FormControl;
  @Input() public controlStateChange?: Observable<void>;

  /**
   * Sets 'required' attribute on the displayable input.
   * Note, the control should be marked as required as well
   * for the parent form group synchronization
   */
  @Input() public required: boolean;

  @Input() public tabIndex = '0';

  /**
   * Used by [(ngModel)].
   * Note we use ngModel instead of FormControl because "mask" directive
   * does not work properly with the control.
   * The issue is the filled value overlays the placeholder on the init.
   */
  public displayValue: string;
  public isDisabled: boolean;

  public errorMatcher: PhoneInputErrorStateMatcher;

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setDisplayValueFromControl();
    this.errorMatcher = new PhoneInputErrorStateMatcher(this.control);
    const sourceValueChangeSub = this.control.valueChanges.subscribe((value) => {
      if (value !== this.displayValue) {
        this.setDisplayValueFromControl();
        this.cdRef.detectChanges();
      }
    });
    this.sub.add(sourceValueChangeSub);

    this.control.registerOnDisabledChange((disabled) => {
      this.isDisabled = disabled;
      this.cdRef.detectChanges();
    });
    this.isDisabled = this.control.disabled;

    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(controlStateSub);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public onUserInputChange(unformattedPhoneNumber: string): void {
    this.control.setValue(unformattedPhoneNumber);
  }

  public onBlur(): void {
    if (!this.control.touched) {
      this.control.markAsTouched();
    }
  }

  private setDisplayValueFromControl(): void {
    // Cut leading '+1' or '1' if any
    this.displayValue = formatPhone(this.control.value);
  }
}
