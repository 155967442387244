import { CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hasPermission, isLoggedIn } from '../../store/auth/account.selectors';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';
import { map, take } from 'rxjs/operators';
import { UnauthorizedRouterService } from '../../routes';
import { NoPermissionsRouterService } from '../../routes/core/no-permissions';

@Injectable({
  providedIn: 'root',
})
export class TripsAuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private unauthRoute: UnauthorizedRouterService,
    private noPermissionsRoute: NoPermissionsRouterService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const isLoggedIn$ = this.store.select(isLoggedIn);
    const hasTripsViewPermissions$ = this.store.select(hasPermission(CharterPermissions.CHARTER_TRIP_VIEW));
    return combineLatest([isLoggedIn$, hasTripsViewPermissions$]).pipe(
      take(1),
      map(([loggedIn, hasTripViewPermissions]) => {
        if (!loggedIn) {
          return this.unauthRoute.getUrlTree({});
        }
        if (!hasTripViewPermissions) {
          return this.noPermissionsRoute.getUrlTree({});
        }
        return true;
      }),
    );
  }
}
