import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { PageHeaderModule } from '../shared/page-header/page-header.module';
import { HomeContentModule } from '../shared/home-content/home-content.module';
import { PageFooterModule } from '../shared/page-footer/page-footer.module';
import { TypographyModule } from '../shared/typography/typography.module';
import { ComponentsModule } from '../shared/components/components.module';
import { SmartFormsModule } from '../shared/smart-forms/smart-forms.module';

import { UnauthorizedComponent } from './containers/unauthorized/unauthorized.component';
import { HomeComponent } from './containers/home/home.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { ApiService, BaseApiService, MockApiService } from './services';
import { CharterCatalogApiService } from './services/api/charter-catalog-api.service';
import { SnackbarModule } from './snackbar/snackbar.module';
import { RedirectComponent } from './components/redirect/redirect.component';
import { NoPermissionsComponent } from './containers/no-permissions/no-permissions.component';

const apiServiceFactory = (baseApi: BaseApiService, catalogApi: CharterCatalogApiService): ApiService => {
  if (cEnvironment.mockApiEnabled) {
    return new MockApiService(baseApi, catalogApi) as any as ApiService;
  }
  return new ApiService(baseApi, catalogApi);
};

@NgModule({
  declarations: [UnauthorizedComponent, HomeComponent, HomeHeaderComponent, RedirectComponent, NoPermissionsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    PageHeaderModule,
    HomeContentModule,
    PageFooterModule,
    TypographyModule,
    RouterModule,
    ComponentsModule,
    SmartFormsModule,
    SnackbarModule,
  ],
  providers: [
    {
      provide: ApiService,
      useFactory: apiServiceFactory,
      deps: [BaseApiService, CharterCatalogApiService],
    },
  ],
})
export class CoreModule {}
