import { createReducer, on } from '@ngrx/store';

import { clearStore } from '../../../../store/common/clear-store.actions';
import * as fromActions from './trip-editor-booking-contact.actions';
import { EntityState, PortalEntity, PortalEntityType, SchoolEmployee } from '@rootTypes';

export interface TripEditorBookingContactState {
  selection?: PortalEntity;
  contactDetails: EntityState<SchoolEmployee>;
}

const createInitialState = (): TripEditorBookingContactState => {
  return {
    contactDetails: { isLoading: false },
  };
};
export const tripEditorBookingContactReducer = createReducer(
  createInitialState(),
  on(
    clearStore,
    fromActions.initBookingContactState,
    fromActions.destroyBookingContactState,
    fromActions.bookingContactRemoved,
    createInitialState,
  ),
  on(fromActions.bookingContactSelected, (state, { value }): TripEditorBookingContactState => {
    return {
      selection: value,
      contactDetails: { isLoading: false },
    };
  }),
  on(fromActions.loadBookingContactDetailsRequested, (state): TripEditorBookingContactState => {
    return {
      ...state,
      contactDetails: { isLoading: true },
    };
  }),
  on(fromActions.loadBookingContactDetailsSuccess, (state, { details }): TripEditorBookingContactState => {
    return {
      ...state,
      contactDetails: {
        isLoading: false,
        entity: details,
      },
    };
  }),
  on(fromActions.loadBookingContactDetailsFailed, (state, { error }): TripEditorBookingContactState => {
    return {
      ...state,
      contactDetails: {
        isLoading: false,
        error,
      },
    };
  }),
  on(
    fromActions.newBookingContactCreated,
    fromActions.bookingContactUpdated,
    (state, { contact }): TripEditorBookingContactState => {
      return {
        ...state,
        selection: {
          type: PortalEntityType.SCHOOL_EMPLOYEE,
          entityId: contact.id,
          label: `${contact.firstName} ${contact.lastName}`,
        },
        contactDetails: {
          isLoading: false,
          entity: contact,
        },
      };
    },
  ),
);
