/**
 * @author Roman Pashkovsky
 * @email petrodzher@gmail.com
 * @create date 2019-07-29 11:27:26
 * @modify date 2019-07-29 11:27:26
 * @desc Encapsulates all firebase oprations
 */
import { Injectable } from '@angular/core';

/** Import firebase */
import * as firebase from 'firebase/app';
import 'firebase/auth';

import { SessionUser } from '@rootTypes/entities/auth';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firebase: any;
  private _auth: any;

  constructor() {
    this.initializeApp();
  }

  public changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const user = this._auth.currentUser;
    const credentials = this.firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
    return user
      .reauthenticateWithCredential(credentials)
      .then(() => user.updatePassword(newPassword))
      .catch((error: any) => {
        if (error?.code !== 'auth/wrong-password') {
          console.error(new Error('Change password failed'), error);
        }
        throw error;
      });
  }

  public createUserWithEmailAndPassword(email: string, password: string): Promise<string> {
    return this._auth.createUserWithEmailAndPassword(email, password).then((user: firebase.auth.UserCredential) => {
      return user!.user!.uid;
    });
  }

  public currentUser(): firebase.User {
    return this.firebase.auth().currentUser;
  }

  public initializeApp(): void {
    const config = {
      apiKey: cEnvironment.fbApiKey,
      authDomain: cEnvironment.fbAuthDomain,
      databaseURL: cEnvironment.fbDatabaseURL,
      projectId: cEnvironment.fbProjectId,
    };
    firebase.initializeApp(config);
    this.firebase = firebase;
    this._auth = this.firebase.auth();
  }

  public uid(): string | null {
    return this._auth.currentUser ? this._auth.currentUser.uid : null;
  }

  public serverValueTimestamp(): any {
    return this.firebase.database.ServerValue.TIMESTAMP;
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    return this.firebase.auth().sendPasswordResetEmail(email);
  }

  public signInWithEmailAndPassword(email: string, password: string): Promise<SessionUser> {
    return this._auth.signInWithEmailAndPassword(email, password).then((user: firebase.auth.UserCredential) => {
      return {
        userId: user!.user!.uid,
      };
    });
  }

  public signInWithLoginToken(token: string): Promise<SessionUser> {
    return this._auth.signInWithCustomToken(token).then((user: firebase.auth.UserCredential) => {
      return {
        userId: user!.user!.uid,
      };
    });
  }

  public signOut(): Promise<void> {
    return this._auth.signOut();
  }

  public token(isRefresh = false): Promise<string> {
    return this._auth.currentUser ? this._auth.currentUser.getIdToken(isRefresh) : Promise.resolve(null);
  }

  public getIdTokenResult(isRefresh = false): Promise<firebase.auth.IdTokenResult> {
    return this._auth.currentUser.getIdTokenResult(isRefresh);
  }

  public onAuthStateChanged(
    observer: (user: firebase.User | null) => any,
    error?: (a: firebase.auth.Error) => any,
  ): firebase.Unsubscribe {
    return this._auth.onAuthStateChanged(observer, error);
  }
}
