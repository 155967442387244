import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../core/services';
import { getCharterConfigFailed, getCharterConfigRequested, getCharterConfigSuccess } from './actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AppConfigEffects {
  constructor(private actions$: Actions, private api: ApiService) {}

  public getCharterConfigRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterConfigRequested),
      switchMap(() => {
        return this.api.getCharterConfig({}).pipe(
          map((data) => getCharterConfigSuccess({ data })),
          catchError((originalError) => {
            const error = {
              originalError,
              text: 'Failed to load charter config',
            };
            return of(getCharterConfigFailed({ error }));
          }),
        );
      }),
    );
  });
}
