import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpApiService } from './http-api.service';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  constructor(private httpApi: HttpApiService) {}

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public get(apiName: string, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.get(apiName, prefixEndpoint));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public post(apiName: string, request: any = {}, prefixEndpoint?: string): Observable<any> {
    return from(
      this.httpApi.post(apiName, request, prefixEndpoint).then((res) => {
        if (!res.data) {
          console.warn('Bad response format');
          console.log(res);
          return res;
        }
        return res.data;
      }),
    );
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public postBlob(apiName: string, request: any = {}, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.postBlob(apiName, request, prefixEndpoint));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public getBlob(apiName: string, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.getBlob(apiName, prefixEndpoint));
  }
}
