import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppConfigState } from './reducer';
import { GetCharterConfigResponse } from '@rootTypes/api';
import { appConfigFeatureKey } from './feature-key';
import { SelectOption } from '../../shared/form-controls';
import { Option } from '@rootTypes/entities/common/option';

const getAppConfigFeature = createFeatureSelector<AppConfigState>(appConfigFeatureKey);

export const getAppConfig = createSelector(getAppConfigFeature, (state): GetCharterConfigResponse => state.entity);
export const isAppConfigLoading = createSelector(getAppConfigFeature, (state) => state?.isLoading || false);
export const isAppConfigResolved = createSelector(getAppConfigFeature, (state) => state?.resolved || false);
export const getAppConfigError = createSelector(getAppConfigFeature, (state) => state.error);
// region options
export const getRegionApiOptions = createSelector(getAppConfig, (state): Option[] => {
  if (!state) {
    return [];
  }
  return state.regions;
});
export const getRegionSelectOptions = createSelector(getRegionApiOptions, (state): SelectOption[] => {
  return state.map((s) => ({ value: s.id, displayLabel: s.label }));
});
// vehicle catalog options
export const getVehicleCatalogApiOptions = createSelector(getAppConfig, (state) => {
  if (!state) {
    return [];
  }
  return state.catalogs || [];
});
export const getVehicleCatalogSelectOptions = createSelector(getVehicleCatalogApiOptions, (state) => {
  return state.map((s) => ({ value: s.id, displayLabel: s.label })) as SelectOption[];
});

export const getDisplayVehicleCatalogOptionLabel = (catalogId) =>
  createSelector(getVehicleCatalogApiOptions, (options) => {
    return (options || []).find(({ id }) => id === catalogId)?.label;
  });

export const selectGeneralContractDistrict = createSelector(getAppConfig, (state) => state?.generalContractDistrict);
export const selectGeneralContractDistrictId = createSelector(selectGeneralContractDistrict, (state) => state?.id);
