import { createAction, props } from '@ngrx/store';
import { TripCustomerSelectValue } from '@rootTypes';

const effects = '[TripCustomerEffects]:';
export const initTripCustomerFromStorage = createAction(
  `${effects} Init customer from storage`,
  props<{ value?: TripCustomerSelectValue }>(),
);

const component = '[TripCustomerSelect]:';
export const tripCustomerChanged = createAction(
  `${component} Customer changed`,
  props<{ value?: TripCustomerSelectValue }>(),
);
