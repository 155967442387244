import { GetCharterTripPurposeResponse } from './index';

export const getCharterTripPurposeMockResponse: GetCharterTripPurposeResponse = {
  tripPurpose: [
    {
      id: 'purpose1',
      label: 'Purpose 1',
    },
    {
      id: 'purpose2',
      label: 'Purpose 2',
    },
    {
      id: 'purpose3',
      label: 'Purpose 3',
    },
  ],
};
