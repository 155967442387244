export enum SearchByRequestType {
  DRIVER = 'driver',
  CUSTOMER = 'customer',
  CAMPUS = 'campus',
  DISTRICT = 'zum_enterprise',
  RIDER = 'rider',
  VENDOR = 'vendor',
  VEHICLE = 'vehicle',
  ZUM_EMPLOYEE = 'zum_employee',
  VENDOR_EMPLOYEE = 'vendor_employee',
  SCHOOL_EMPLOYEE = 'school_employee',
}

export enum EntitySearchType {
  DRIVER = 'driver',
  RIDE = 'ride',
  ROUTE = 'route',
  PARENT = 'parent',
  STUDENT = 'student',
  DISTRICT = 'district',
  CAMPUS = 'campus',
  SCHOOL = 'school',
  VENDOR = 'vendor',
  VEHICLE = 'vehicle',
  YARD = 'yard',
  ZUM_EMPLOYEE = 'zum_employee',
  VENDOR_EMPLOYEE = 'vendor_employee',
  SCHOOL_EMPLOYEE = 'school_employee',
  CHARTER_TRIPS = 'charter_trip',
}

export type ApiEntitySearchHit = {
  label: string; // Label to show up in the search drop down
  pillLabel: string; // Label show in the pill text
  field: string; // Name of the request field which can be used to call in ride or routes api
  value: string; // value of the search term
};

export type EntitySearchResult =
  | EntitySearchResultDistrict
  | EntitySearchResultCampus
  | EntitySearchResultSchoolEmployee
  | EntitySearchResultDriver
  | EntitySearchResultParent
  | EntitySearchResultRide
  | EntitySearchResultRoute
  | EntitySearchResultStudent
  | EntitySearchResultSchool
  | EntitySearchResultVendor
  | EntitySearchResultYard
  | EntitySearchResultVehicle
  | EntitySearchResultZumEmployee
  | EntitySearchResultVendorEmployee
  | EntitySearchResultCharterTrip;

export type EntitySearchResultDistrict = {
  type: EntitySearchType.DISTRICT;
  districtId: string;
  label: string; // district name
};

type EntitySearchResultCampus = {
  type: EntitySearchType.CAMPUS;
  districtId: string;
  campusId: string;
  label: string; // campus secondary name
};

type EntitySearchResultSchoolEmployee = {
  type: EntitySearchType.SCHOOL_EMPLOYEE;
  districtId: string;
  schoolEmployeeId: string;
  label: string;
  email: string;
  organizationName?: string;
  phoneNumber?: string;
};

export type EntitySearchResultDriver = {
  type: EntitySearchType.DRIVER;
  driverId: string;
  label: string; // FN + LN
};

type EntitySearchResultParent = {
  type: EntitySearchType.PARENT;
  parentId: string;
  label: string; // FN + LN
};

export type EntitySearchResultRide = {
  type: EntitySearchType.RIDE;
  rideId: string;
  label: string;
};

type EntitySearchResultRoute = {
  type: EntitySearchType.ROUTE;
  routeId: string;
  label: string;
};

export type EntitySearchResultStudent = {
  type: EntitySearchType.STUDENT;
  studentId: string;
  parentId: string;
  label: string; // FN + LN
};

type EntitySearchResultSchool = {
  type: EntitySearchType.SCHOOL;
  districtId: string;
  campusId: string;
  label: string;
};

export type EntitySearchResultVendor = {
  type: EntitySearchType.VENDOR;
  vendorId: string;
  label?: string;
};

export type EntitySearchResultYard = {
  type: EntitySearchType.YARD;
  vendorId: string;
  yardId: string;
  code: string;
  label?: string;
};

export type EntitySearchResultVehicle = {
  type: EntitySearchType.VEHICLE;
  vehicleId: string;
  vehicleDisplayId?: string;
  imagePath: string;
  make: string;
  model: string;
  license: {
    number: string;
    state: string;
  };
  assignedDriverId?: string;
  assignedDriver?: {
    id: string;
    // firstName: string;
    // lastName: string;
  };
};

export type EntitySearchResultZumEmployee = {
  type: EntitySearchType.ZUM_EMPLOYEE;
  zumEmployeeId: string;
  label: string;
};

export type EntitySearchResultVendorEmployee = {
  type: EntitySearchType.VENDOR_EMPLOYEE;
  vendorEmployeeId: string;
  vendorId: string;
  label: string;
};

export type EntitySearchResultCharterTrip = {
  type: EntitySearchType.CHARTER_TRIPS;
  tripId: string;
  label: string;
};
