<div class="wp-ride-list-result-bar">
  <ng-container *ngIf="(totalFound !== null && totalFound !== undefined)">
    <div
      *ngIf="totalFound <= maxQuantity"
      class="wp-ride-list-result-bar__left"
    >
      {{ totalFound | displayQuantity: entityName:customPluralForm }} found
    </div>
    <div *ngIf="totalFound > maxQuantity" class="wp-ride-list-result-bar__left">
      {{ maxQuantity }}+ {{ customPluralForm ? customPluralForm : entityName +
      's'}} found
    </div>
  </ng-container>

  <ng-container *ngIf="centerContent">
    <ng-template [ngTemplateOutlet]="centerContent"></ng-template>
  </ng-container>

  <div class="wp-ride-list-result-bar__right">
    <div *ngIf="totalFound" class="wp-ride-list-result-bar__list-showing">
      <span class="slate-grey">Showing </span>
      <span class="bold">{{ showingFrom }} - {{ showingTo }} </span>
      <span class="slate-grey"> of </span>
      <span class="bold" *ngIf="totalFound <= maxQuantity">
        {{ totalFound }}
      </span>
      <span class="bold" *ngIf="totalFound > maxQuantity">
        {{ maxQuantity }}+
      </span>
      <span class="slate-grey"> results </span>
    </div>
  </div>
</div>
