import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl } from '@angular/forms';

export class PhoneInputErrorStateMatcher implements ErrorStateMatcher {
  constructor(private control: FormControl) {}

  isErrorState(control: FormControl): boolean {
    return (
      this.control?.touched && this.control?.status !== 'VALID' && Object.keys(this.control?.errors || {}).length > 0
    );
  }
}
