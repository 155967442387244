<footer>
  <span class="year">@{{currYear}} Zum</span>

  <c-divider-vertical-line
    [isWhite]="true"
    [containerHeight]="'20px'"
    [height]="'14px'"
    [margin]="'0 25px 0 15px'"
  ></c-divider-vertical-line>

  <wp-link
    class="link"
    [isUnderlined]="false"
    [isWhite]="true"
    [fontSize]="'12px'"
    [openInNewTab]="true"
  >
    Contact us
  </wp-link>

  <wp-link
    class="link"
    [isUnderlined]="false"
    [isWhite]="true"
    [fontSize]="'12px'"
    [openInNewTab]="true"
  >
    Terms, Privacy
  </wp-link>

  <wp-link
    class="link"
    [isUnderlined]="false"
    [isWhite]="true"
    [fontSize]="'12px'"
    [openInNewTab]="true"
    [href]="'https://www.ridezum.com'"
  >
    ridezum.com
  </wp-link>
</footer>
