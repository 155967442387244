import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GoogleMapsInitService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class GoogleInitGuardService implements CanActivate {
  constructor(private googleInitService: GoogleMapsInitService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.googleInitService.initialize();
  }
}
