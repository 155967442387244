import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { homeRoute } from '../../../routes';

@Component({
  selector: 'c-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent implements OnInit {
  public homeLink: string;

  public ngOnInit(): void {
    this.homeLink = homeRoute.getHref();
  }
}
