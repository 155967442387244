import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  ReflectiveInjector,
  AfterViewInit,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { take, filter, switchMap, map, shareReplay } from 'rxjs/operators';

import { AbstractPopupComponent, PopupOptions, PopupRef, ComponentItem } from '../types';

@Component({
  selector: 'c-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements AfterViewInit, OnInit {
  private static componentSubj: Subject<ComponentItem> = new Subject<ComponentItem>();
  private static closedPopup: Subject<any> = new Subject<any>();

  public isOpen$: Observable<boolean> | undefined;
  public options$: Observable<PopupOptions>;
  public contentZIndex$: Observable<number>;
  public pageMaskZIndex$: Observable<number>;

  public static open<C extends AbstractPopupComponent<D, R>, D, R>(
    component: C,
    data: D,
    options?: PopupOptions,
  ): Observable<R> {
    this.componentSubj.next({ name: component, data, options, popupRef: new PopupRef() });

    return this.closedPopup.asObservable().pipe(take(1));
  }

  public static close(): void {
    PopupComponent.closedPopup.next(undefined);
    PopupComponent.componentSubj.next(undefined);
  }

  @ViewChild('container', { read: ViewContainerRef }) private containerRef!: ViewContainerRef;

  constructor(private cd: ChangeDetectorRef, private resolver: ComponentFactoryResolver) {}

  ngOnInit() {}

  ngAfterViewInit() {
    PopupComponent.componentSubj
      .asObservable()
      .pipe(
        filter((cmp) => !!cmp),
        switchMap((cmp) => this.onComponentNameChanged(cmp)),
        map((resultData) => {
          PopupComponent.closedPopup.next(resultData);
        }),
      )
      .subscribe();

    this.isOpen$ = PopupComponent.componentSubj.asObservable().pipe(
      switchMap((cmp) => {
        if (!(cmp && cmp.popupRef)) {
          return of(false);
        }
        return cmp.popupRef.isOpen$;
      }),
    );
    this.options$ = PopupComponent.componentSubj.asObservable().pipe(
      map((comp) => {
        if (comp && comp.options) {
          return comp.options;
        }
        return null;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.contentZIndex$ = this.options$.pipe(
      map((options) => {
        if (options && options.contentZIndex) {
          return options.contentZIndex;
        }
        return 12;
      }),
    );
    this.pageMaskZIndex$ = this.options$.pipe(
      map((options) => {
        if (options && options.pageMaskZIndex) {
          return options.pageMaskZIndex;
        }
        return 11;
      }),
    );

    PopupComponent.closedPopup.asObservable().subscribe(() => {
      this.containerRef.clear();
    });
  }

  private onComponentNameChanged(component: ComponentItem): Observable<any> {
    const popupRef = component.popupRef;
    popupRef.data = component.data;
    const inputs = { popupRef };
    const inputProviders = Object.keys(inputs).map((inputName) => {
      return { provide: inputName, useValue: (inputs as any)[inputName] };
    });
    const resolvedInputs = ReflectiveInjector.resolve(inputProviders);
    const injector: ReflectiveInjector = ReflectiveInjector.fromResolvedProviders(
      resolvedInputs,
      this.containerRef.parentInjector,
    );
    const factory = this.resolver.resolveComponentFactory(component.name as any);
    const cmp = factory.create(injector);
    (cmp.instance as any)['popupRef'] = popupRef;
    this.containerRef.insert(cmp.hostView);
    this.cd.detectChanges();
    return popupRef.closed();
  }

  public onClose(): void {
    PopupComponent.close();
  }
}
