import { createAction, props } from '@ngrx/store';

const createTripStopsComponent = '[CreateTripStops]:';
export const initializeNewTrip = createAction(
  `${createTripStopsComponent} Initialize new trip`,
  props<{ defaultDistrict: { id: string; name: string }; isDistrictDisabled: boolean }>(),
);

export const newTripItineraryChanged = createAction(`[CreateTripStopsChange]: Itinerary changed`);

const createTripVehiclesComponent = '[CreateTripVehicles]:';
export const initializeNewTripVehicles = createAction(
  `${createTripVehiclesComponent} Initialize new trip vehicles`,
  props<{ tripItineraryId: string }>(),
);
export const createTripVehiclesSaveStateToStorage = createAction(
  `${createTripVehiclesComponent} Save state to storage`,
);

export const initializeNewTripReview = createAction(
  '[CreateTripReview]: Initialize new trip review',
  props<{ tripItineraryId: string }>(),
);

const createTripReservationComponent = '[CreateTripReservation]:';
export const initializeNewTripReservation = createAction(
  `${createTripReservationComponent} Initialize new trip reservation`,
  props<{ tripItineraryId: string }>(),
);

export const createNewTripSuccess = createAction(`[CreateTripSuccess]: New trip created`);
