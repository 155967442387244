import { CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { hasPermission, isLoggedIn } from '../../store/auth/account.selectors';
import { CharterPermissions } from '@rootTypes/entities/auth/charter-permissions';
import { map, take } from 'rxjs/operators';
import { TripListRouterService, UnauthorizedRouterService } from '../../routes';

@Injectable({
  providedIn: 'root',
})
export class BookingFlowAuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private unauthRoute: UnauthorizedRouterService,
    private tripsRoute: TripListRouterService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const isLoggedIn$ = this.store.select(isLoggedIn);
    const hasBookingFlowPermission$ = this.store.select(hasPermission(CharterPermissions.CHARTER_TRIP_BOOKING_EDIT));
    return combineLatest([isLoggedIn$, hasBookingFlowPermission$]).pipe(
      take(1),
      map(([loggedIn, hasBookingFlowPermission]) => {
        if (!loggedIn) {
          return this.unauthRoute.getUrlTree({});
        }
        if (!hasBookingFlowPermission) {
          return this.tripsRoute.getUrlTree({});
        }
        return true;
      }),
    );
  }
}
