import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAccountRequested } from '../../store/auth/actions';
import { Injectable } from '@angular/core';
import { isAccountResolved } from '../../store/auth/account.selectors';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInitGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(getAccountRequested());
    return this.store.select(isAccountResolved).pipe(
      filter((resolved) => !!resolved),
      take(1),
      map(() => true),
    );
  }
}
