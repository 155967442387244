import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wp-btn-secondary',
  templateUrl: './btn-secondary.component.html',
  styleUrls: ['./btn-secondary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnSecondaryComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public slim = false;
  @Input() public height!: string;
  @Input() public width!: string;
  @Input() public loading = false;
  @Input() public tabIndex = 0;

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    this.height = this.slim ? '36px' : this.height || '46px';
  }
  public btnSize(): any {
    return { width: this.width, height: this.height };
  }

  public onClick(): void {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }
}
