import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractDropdown } from '../abstract-dropdown';
import { FormControl } from '@angular/forms';
import { SelectOption } from '../..';

@Component({
  selector: 'wp-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent extends AbstractDropdown implements OnInit, OnDestroy, OnChanges {
  @Input() public disabled = false;
  @Input() public label!: string;
  @Input() public control!: FormControl;
  @Input() public options: SelectOption[] = [];
  @Input() public triggerTemplate: any;
  @Input() public dropdownTemplate: any;
  @Input() public dropdownVerticalOffset = '40px';
  @Input() public alignDropdownVertical: 'top' | 'bottom' = 'bottom';
  @Input() public alignDropdown: 'left' | 'right' | 'center' | 'full-width' = 'full-width';
  @Input() public tabIndex = '1';
  @Input() public isDropdownPositionRelative = true;
  @Input() public dropdownWidthPx?: number;

  public dropdownTopPosition: string;
  public dropdownBottomPosition: string;
  public dropdownWidth: string;

  @Output() public closedDropdown: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.onNgInit();
  }

  ngOnDestroy(): void {
    super.onNgOnDestroy();
  }

  public onCloseDropdown(): void {
    this.closedDropdown.emit();
    super.closeDropdown();
  }

  private getDropdownTopPosition(): string {
    if (this.alignDropdownVertical === 'bottom') {
      return this.dropdownVerticalOffset;
    } else {
      return 'auto';
    }
  }

  private getDropdownBottomPosition(): string {
    if (this.alignDropdownVertical === 'top') {
      return this.dropdownVerticalOffset;
    } else {
      return 'auto';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alignDropdownVertical || changes.dropdownVerticalOffset) {
      this.dropdownTopPosition = this.getDropdownTopPosition();
      this.dropdownBottomPosition = this.getDropdownBottomPosition();
    }
    if (changes.dropdownWidthPx) {
      if (typeof this.dropdownWidthPx === 'number') {
        this.dropdownWidth = `${this.dropdownWidthPx}px`;
      } else {
        this.dropdownWidth = undefined;
      }
    }
  }
}
