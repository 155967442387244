import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-disabled-mask',
  templateUrl: './disabled-mask.component.html',
  styleUrls: ['./disabled-mask.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisabledMaskComponent {
  @Input() transparent = false;
  @Input() isSpinner = false;
}
