import { createAction, props } from '@ngrx/store';
import { GetCharterConfigResponse } from '@rootTypes/api';
import { WpError } from '@rootTypes';

export const getCharterConfigRequested = createAction('[HomeComponent] API getCharterConfig request');
export const getCharterConfigSuccess = createAction(
  '[HomeComponent] API getCharterConfig success',
  props<{ data: GetCharterConfigResponse }>(),
);
export const getCharterConfigFailed = createAction(
  '[HomeComponent] API getCharterConfig failed',
  props<{ error: WpError }>(),
);
